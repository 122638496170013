import { render, staticRenderFns } from "./bigScreenMap.vue?vue&type=template&id=28761994&scoped=true&"
import script from "./bigScreenMap.vue?vue&type=script&lang=ts&"
export * from "./bigScreenMap.vue?vue&type=script&lang=ts&"
import style0 from "./bigScreenMap.vue?vue&type=style&index=0&id=28761994&prod&scoped=true&lang=stylus&"
import style1 from "./bigScreenMap.vue?vue&type=style&index=1&id=28761994&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28761994",
  null
  
)

export default component.exports